<template>
  <v-container>
    <go-back-btn :routeToGo="routeToGo" />
    <page-header class="mb-3" :title="title + clienteNombre" />
    <v-data-table
      :headers="headers"
      :items="servidoresCliente"
      :single-expand="singleExpand"
      :search="search"
      :footer-props="{ 'items-per-page-text': 'Servidores por página' }"
      :expanded.sync="expanded"
      item-key="servidoresClientesId"
      show-expand
      class="elevation-1"
      :loading="isLoading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col cols="8">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar servidor"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="1">
            </v-col>
            <v-col cols="3" class="pt-5 text-right">
              <v-btn
                color="primary"
                @click="newItem()"
                class="text-right mr-2"
              >
                Nuevo
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
       <td :colspan="headers.length">
              <strong>Fecha alta:</strong>
              {{ item.fecAlta }}
              <strong style="padding-left: 80px">Usuario alta:</strong>
              {{ item.usuAlta }}
              <strong style="padding-left: 80px">Fecha modificación:</strong>
              {{ item.fecModi }}
              <strong style="padding-left: 80px">Usuario modificación:</strong>
              {{ item.usuModi }}
       </td>
        
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar servidor</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
          <span>Eliminar servidor</span>
        </v-tooltip>
      </template>
      
    </v-data-table>
    <v-dialog v-model="dialog" max-width="40%" @keydown.esc="close()">
      <v-card outlined>
        <v-card-title>
          <span class="py-1 primary--text">{{ formEditTitle }} {{this.clienteNombre}}</span>
        </v-card-title>
        <v-card-text class="pb-2">
          <v-form
            v-model="isFormValid"
            ref="form"
            form="form"
            id="form"
            @submit.prevent="save()"
          >

          <v-row>
            <!-- Nombre servidor -->
            <v-col cols="12" sm="6" md="12" class="py-0">
              <v-autocomplete
                v-model="servidorSelected"
                :items="servidorSistema"
                item-text="value"
                item-value="id"
                label="Nombre de servidor"
                :rules="[...rules.required]"
                outlined
                clearable
                autocomplete="off"
                dense
              >
              </v-autocomplete>
            </v-col>
            <!-- Descripcion -->
            <v-col cols="12" md="12" class="py-0">
              <v-textarea
                v-model="descripcion"
                :rules="[...rules.required]"
                label="Descripción"
                dense
                auto-grow
                rows="2"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="close">
            <span align="center"> Cancelar </span>
          </v-btn>
          <v-btn
            color="#195472"
            type="submit"
            :disabled="!isFormValid"
            form="form"
          >
            <span class="myLabel" align="center">Guardar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDelete"
      max-width="30%"
      @keydown.esc="dialogDelete = false"
    >
      <v-card>
        <v-card-title class="primary--text d-flex mb-1"
          >¿Eliminar servidor?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="text-left" text @click="closeDelete">Cancelar</v-btn>
          <v-btn text color="primary" dark @click="deleteItemConfirm"
            >Eliminar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, } from "vuex";
import PageHeader from "@/components/ui/PageHeader";
import GoBackBtn from "@/components/shared/GoBackBtn";
import rules from "@/utils/helpers/rules";

//import enumsAdmin from "@/utils/enums/modules/index.js";
import { mask } from "vue-the-mask";

export default {
  components: { PageHeader, GoBackBtn },
  directives: { mask },
  name: "ServidoresSistemas",

  data() {
    return {
      rules: rules,
      isLoading: false,
      dialog: false,
      expanded: [],
      servidorSistema: [],
      isFormValid: false,
      servidoresCliente: [],
      formEditTitle: "Editar servidor del cliente: ",
      search: "",
      dialogDelete: false,
      routeToGo: "/clientes",
      singleExpand: true,
      // optionCode: enums.webSiteOptions.FECHA_CORTE,
      date: null,
      dateFormatted: null,
      menu: false,
      title: "Servidores ",
      headers: [
        { text: "Servidor", value: "servidorNombre", sortable: true },
        { text: "Descripcion", value: "descripcion", sortable: true },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      clienteId: null,
      clienteNombre:null,
      descripcion:null,
      servidorSelected:null,

      // allowedActions: null,
      canFechaCorte: false,
    };
  },
  // computed: {
  //   ...mapGetters({
  //     alert: "alert",
  //   }),
  // },
  created() {
    this.clienteId = this.$route.params.itemsClientes.clienteId
    this.clienteNombre = this.$route.params.itemsClientes.clienteNombre
    this.setServidoresSistema();
    this.loadServidores();
    // this.setPermisos();
  },

  methods: {
    ...mapActions({
      getServidoresbyCliente: "clientesData/getServidoresbyCliente",
      saveServidor: "clientesData/saveServidor",
      deleteServidor: "clientesData/deleteServidor",
      servidoresTaxonomy: "clientesData/servidoresTaxonomy",
      setAlert: "user/setAlert",
    }),
    async loadServidores() {
      this.isLoading = true;
      const responseaxios = await this.getServidoresbyCliente(this.clienteId);
      this.servidoresCliente = responseaxios;
      this.isLoading = false;
    },
    async setServidoresSistema(){
      const resp = await this.servidoresTaxonomy();
      this.servidorSistema = resp
    },
    editItem(item) {
      this.formEditTitle = "Editar servidor del cliente: ",
      //this.clienteId = item.clienteId;
      this.servidoresClientesId = item.servidoresClientesId
      this.clienteNombre = this.$route.params.itemsClientes.clienteNombre
      this.servidorSelected = item.servidorTipoId
      this.descripcion = item.descripcion

      this.dialog = true;
    },
    newItem(){
      this.formEditTitle = "Nuevo servidor para el cliente: "
      this.clienteNombre = this.$route.params.itemsClientes.clienteNombre
      this.dialog = true;
    },
    deleteItem(item) {
      this.servidoresClientesId = item.servidoresClientesId;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      const res = await this.deleteServidor(this.servidoresClientesId);
      if (res.status == 200) {
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.closeDelete();
      }
    },
    close() {
      this.servidorSelected = null;
      this.descripcion = null;
      this.servidoresClientesId=null,
  // Reinicia las reglas de validación
      this.$refs.form.resetValidation();
      this.loadServidores();
      this.dialog = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      setTimeout(() => {
        this.loadServidores();
      }, 500);
    },
    async save() {
     let payload = {
      servidoresClientesId: this.servidoresClientesId,
      clienteId: this.clienteId,
      ServidorTipoId: this.servidorSelected,
      descripcion : this.descripcion
      };
     const res = await this.saveServidor(payload);
      if (res.status == 200) {
        this.setAlert({ type: "success", message: "Actualizado con éxito" });
      }
      this.close();
    },
  },
};
</script>
<style scoped>
::v-deep .myLabel {
  color: white;
}
</style>
